import React, { useState, useEffect } from "react";
import Modal from "antd/lib/modal/Modal";
import {
  Form,
  Input,
  InputNumber,
  Button,
  notification,
  Select,
  Checkbox,
  Row,
  Col,
} from "antd";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";

const Completeform = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [selectedRowInfo, setSelectedRowInfo]: any = useState({});
  const [username, setUsername]: any = useState(
    localStorage.getItem("username")
  );
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [locationsList, setLocationsList] = useState([]);
  const [searchFromLocationsList, setSearchFromLocationsList] = useState([]);
  const [selectedFromLocation, setSelectedFromLocation]: any = useState({});
  const [copyinterCityList1, setcopyinterCityList]: any = useState([]);
  const [selectedValues, setSelectedValues]: any = useState({});
  const [vehicleTypeList, setVehicleTypeList] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [NightFareList, setNightFareList] = useState([]);
  const [tripTypeList, setTripTypeList]: any = useState([]);
  const [interCityReturnList, setInterCityReturnList] = useState([]);
  const [hillsChecked, sethillsChecked] = useState(false);
  const [creditChecked, setCreditChecked] = useState(false);
  const [isaddfare, setisaddfare] = useState(false);
  const [Interfare, setinterfare] = useState(false);
  const [roleid, setroleid]: any = useState(localStorage.getItem("roleid"));
  const [km, setkm]: any = useState({});

  const { Option } = Select;

  useEffect(() => {
    setVisible(props.visible);
    if (props.selectedRowData && props.selectedRowData.length > 0) {
      setSelectedRowInfo(props.selectedRowData[0]);
     // sethillsChecked(props.selectedRowData[0].isgst);
      setCreditChecked(props.selectedRowData[0].iscredit);
      setisaddfare(props.selectedRowData[0].isadditionalfare);
      const triptime = diff_hours(
        new Date(props.selectedRowData[0].tripstart),
        new Date(props.selectedRowData[0].tripend)
      );
      const obj = { ...props.selectedRowData[0] };
      if (props.selectedRowData[0].faretype.includes("Intercity Fare")) {
        setinterfare(props.selectedRowData[0].fare);
      }
      obj.tripendtime = triptime || 0;
      obj.tripminutes = triptime || 0;
      setSelectedRowInfo(obj);
      initialloaddata(obj);
      // props.selectedRowData[0].toloclat(selectedRowInfo.toloclat)
      // props.selectedRowData[0].toloclong(selectedRowInfo.toloclong)
      // props.selectedRowData[0].toloc(selectedRowInfo.toloc)
    }
  }, [props.selectedRowData, props.visible]);

  const getTripTypeList = async () => {
    try {
      const response = await axiosGet(UrlConstants.getTripTypeList);
      setTripTypeList(response);
    } catch { }
  };

  const handleOk = (e: any) => {
    props.hideModal(false);
  };

  const handleCancel = (e: any) => {
    props.hideModal(false);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = (values: any) => { };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };
  const initialloaddata = async (selectedRowData: any) => {
    try {
      await getVechileTypeList(branchid);
      await getTripTypeList();
      await getPackagesList(branchid, selectedRowData.vehicletypeid);
      await getNightFarelist(branchid, selectedRowData.vehicletypeid);
      await getLocations(branchid);
      await getintercitylist(branchid);
    } catch (ex) {
      console.log(ex);
    }

    initfarecalc(selectedRowData);

    // use eff

    if (selectedRowData.faretype.includes("Intercity Fare")) {
      let selectedValuesCopy = { ...selectedRowData };
      selectedValuesCopy.tripType = "3";
      let basekm = selectedRowData.intercitytype.split("-")[0];
      let selectedintercityType: any = copyinterCityList1.filter(
        (intercity: any) => intercity.basekm === basekm
      );

      if (selectedintercityType.length > 0) {
        selectedValuesCopy.intercitytype = selectedintercityType[0].basekm;
      }
      setSelectedValues(selectedValuesCopy);
    } else if (selectedRowData.faretype.includes("Package Fare")) {
      let selectedValuesCopy = { ...selectedRowData };
      selectedValuesCopy.tripType = "2";
      let packageType = selectedRowData.packagefaretype.replace("HRS", "Hours");
      let selectedpackageType: any = packagesList.filter(
        (pack: any) => pack.package == packageType
      );

      if (selectedpackageType.length > 0) {
        selectedValuesCopy.packagefaretype = selectedpackageType[0].id;
      }
      setSelectedValues(selectedValuesCopy);
    } else if (selectedRowData.faretype.includes("Night Fare")) {
      let selectedValuesCopy = { ...selectedRowData };
      selectedValuesCopy.tripType = "4";
      let Nighttype = selectedRowData.nightfaretype.replace("HRS", "Hours");
      let selectednightType: any = NightFareList.filter(
        (pack: any) => pack.package == Nighttype
      );

      if (selectednightType.length > 0) {
        selectedValuesCopy.nightfaretype = selectednightType[0].id;
      }
      setSelectedValues(selectedValuesCopy);
    } else if (selectedRowData.faretype.includes("Meter Fare")) {
      let selectedValuesCopy = { ...selectedRowData };
      selectedValuesCopy.tripType = "1";
      setSelectedValues(selectedValuesCopy);
    }
  };

  const initfarecalc = (rowdata: any) => {
    if (rowdata.faretype.includes("Intercity Fare"))
      calculateintercityFare(
        rowdata.distance,
        rowdata.vehicletypeid,
        rowdata.tripminutes
      );
    if (rowdata.faretype.includes("Meter Fare")) {
      calculateMeterFare(
        rowdata.distance,
        branchid,
        rowdata.vehicletypeid,
        rowdata.tripminutes
      );
      // setSelectedRowInfo(rowdata);
    } else if (rowdata.faretype.includes("Package Fare")) {
      calculatepackage(
        rowdata.vehicletypeid,
        rowdata.distance,
        rowdata.tripminutes,
        isaddfare
      );
    } else if (rowdata.faretype.includes("Night Fare")) {
      calculatenight(
        rowdata.vehicletypeid,
        rowdata.distance,
        rowdata.tripminutes,
        isaddfare
      );
    }
  };

  const onchangekm = (value: any) => {
    if (value != "") {
      if (selectedRowInfo.faretype.includes("Intercity Fare")) {
        const basekm = selectedRowInfo.intercitytype?.split("-")[0];
        if (Number(basekm) < Number(value))
          calculateintercityFare(
            value,
            selectedRowInfo.vehicletypeid,
            selectedRowInfo.tripendtime
          );
      } else if (selectedRowInfo.faretype.includes("Meter Fare"))
        calculateMeterFare(
          value,
          branchid,
          selectedRowInfo.vehicletypeid,
          selectedRowInfo.tripendtime
        );
      else if (selectedRowInfo.faretype.includes("Package Fare")) {
        calculatepackage(
          selectedRowInfo.vehicletypeid,
          value,
          selectedRowInfo.tripendtime
        );
      } else if (selectedRowInfo.faretype.includes("Night Fare")) {
        calculatenight(
          selectedRowInfo.vehicletypeid,
          value,
          selectedRowInfo.tripendtime
        );
      }
    }
  };

  const getintercitylist = async (cityId: any) => {
    const options = {
      params: {
        cityid: cityId,
        vehicletype: selectedRowInfo.vehicletypeid,
      },
    };
    try {
      const response = await axiosGet(UrlConstants.getInterCityList, options);
      setcopyinterCityList(response);
    } catch { }
    try {
      const response1 = await axiosGet(
        UrlConstants.getInterCityReturnList,
        options
      );
      setInterCityReturnList(response1);
    } catch { }
  };

  const getPackagesList = async (cityId: any, vehicleid: any) => {
    const uri =
      UrlConstants.getPackagesList +
      "?vehicleid=" +
      vehicleid +
      "&cityid=" +
      cityId;
    try {
      const response = await axiosGet(uri);
      setPackagesList(response);
    } catch { }
  };
  const getNightFarelist = async (cityId: any, vehicleid: any) => {
    const uri =
      UrlConstants.getNightFareType +
      "?vehicleid=" +
      vehicleid +
      "&cityid=" +
      cityId;
    try {
      const response = await axiosGet(uri);
      setNightFareList(response);
    } catch { }
  };
  const getVechileTypeList = async (cityId: any) => {
    const options = {
      params: {
        cityid: cityId,
      },
    };
    const uri = UrlConstants.getVehicleTypeList + "?cityid=" + cityId;
    try {
      const response = await axiosGet(uri);
      setVehicleTypeList(response);
    } catch { }
  };

  const calculateintercityFare = async (
    distance: any,
    vehiycleType = selectedRowInfo.vehicletypeid,
    mins: any,
    isadditionalfare = isaddfare
  ) => {


    let copyinterCityList: any = copyinterCityList1;
    for (let i = 0; i < copyinterCityList.length; i++) {
      let a = i + 1;
      if (copyinterCityList.length > a) {
        // condistance = current distance > approx distance
        if (
          Number(distance) >= copyinterCityList[i].tollerenckm &&
          Number(distance) < copyinterCityList[a].tollerenckm
        ) {
          let vehicleindex = vehicleTypeList.findIndex(
            (obj: { id: any }) => obj.id === vehiycleType
          );
          let vechicletype: any = vehicleTypeList[vehicleindex];
          calculateinterFare(
            distance,
            copyinterCityList[i].basekm,
            copyinterCityList[i].basefare,
            vechicletype.intaddkmcharge,
            vechicletype.addminutecharge,
            copyinterCityList[i].baseminute,
            mins,
            isadditionalfare
          );
          return copyinterCityList[i];
        }
      } else if (copyinterCityList.length == a) {
        if (Number(distance) >= copyinterCityList[i].tollerenckm) {
          let vehicleindex = vehicleTypeList.findIndex(
            (obj: { id: any }) => obj.id === selectedValues.vehicletypeid
          );
          let vechicletype: any = vehicleTypeList[vehicleindex];

          calculateinterFare(
            distance,
            copyinterCityList[i].basekm,
            copyinterCityList[i].basefare,
            vechicletype.intaddkmcharge,
            vechicletype.addminutecharge,
            copyinterCityList[i].baseminute,
            mins,
            isadditionalfare
          );
          return copyinterCityList[i];
        }
      }
    }
  };
  const calculateinterFare = (
    distance: any,
    BaseKM: any,
    BaseFare: any,
    intaddkmcharge: any,
    addminutecharge: any,
    baseminute: any,
    mins: any = 0,
    isadditionalfare?: any,
    isgst?: any
  ) => {
    var approximate_fare = BaseFare;
    var KM = Number(BaseKM);
    if (KM >= Number(distance)) approximate_fare = approximate_fare;
    else if (KM < Number(distance)) {
      var extraKM = Number(distance) - KM;
      approximate_fare =
        Number(approximate_fare) + extraKM * Number(intaddkmcharge);
    }
    if (mins > Number(baseminute)) {
      var extramin = Number(mins) - Number(baseminute);
      approximate_fare =
        Number(approximate_fare) + extramin * Number(addminutecharge);
    }
    let obj: any = { ...selectedRowInfo };
    obj.distance = distance;
    obj.tripendtime = mins;
    if (props.selectedRowData[0].isgst || hillsChecked) {
      obj.fare = Math.round(
        Math.round(
          (Number(approximate_fare) +
            (Number(approximate_fare) / 100) * 30 +
            Number.EPSILON) *
          100
        ) / 100);
    }
    else obj.fare =  Math.round(Math.round(approximate_fare * 100) / 100);



    setSelectedRowInfo(obj);
  };

  const calculateMeterFare = (
    distance: any,
    city: any,
    vehicleId: any,
    min: any,
    isadditionalfare = isaddfare
  ) => {
    try {
      distance = distance.replace(/[^\d.-]/g, "");
    } catch { }
    const uri =
      UrlConstants.getMeterFare +
      "?distance=" +
      distance +
      "&branchid=" +
      city +
      "&vehicleid=" +
      vehicleId;

    axiosGet(uri)
      .then((resp: any) => {
        //resp[0].tempapproximatefare = resp[0].approximatefare;
        let obj = { ...props.selectedRowData[0] };
        obj.basemin = Number(resp[0].baseminute.replace(/\D/g, ""));
        obj.mincharge = Number(resp[0].addminutecharge.replace(/\D/g, ""));
        let charge = 0;
        if (min > Number(resp[0].baseminute.replace(/\D/g, ""))) {
          let extra = min - Number(resp[0].baseminute.replace(/\D/g, ""));
          charge = extra * Number(resp[0].addminutecharge.replace(/\D/g, ""));
        }
        resp[0].approximatefare = Number(resp[0].approximatefare) + charge;
        resp[0].tempapproximatefare = resp[0].approximatefare;
        if (isadditionalfare) {
          resp[0].approximatefare = Math.round(
            Math.round(
              (Number(resp[0].approximatefare) +
                (Number(resp[0].tempapproximatefare) / 100) * 30 +
                Number.EPSILON) *
              100
            ) / 100);
        }

        if (selectedRowInfo.isgst) {
          resp[0].approximatefare = Math.round(
            Math.round(
              (Number(resp[0].approximatefare) +
                (Number(resp[0].tempapproximatefare) / 100) * 30 +
                Number.EPSILON) *
              100
            ) / 100);
        }
        obj.fare = Number(resp[0].approximatefare);
        obj.tripendtime = min;
        obj.distance = distance;
        setSelectedRowInfo(obj);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };

 
  const fareChange = (e: any) => {
    let copyselectedvalues = { ...selectedRowInfo };
    copyselectedvalues.fare = e;
    setSelectedValues(copyselectedvalues);
    setSelectedRowInfo(copyselectedvalues);
  };
  const completetrip = () => {
    // var diffmin = diff_hours(
    //   new Date(props.selectedRowData[0].assignedtime),
    //   new Date()
    // );

    // if (
    //   Number(roleid) === 1 ||
    //   Number(roleid) === 2 ||
    //   Number(roleid) === 7 ||
    //  // diffmin >= selectedRowInfo.tripendtime
    // ) {
      const options = {
        data: {
          cabid: props.selectedRowData[0].cabid,
          bookingid: props.selectedRowData[0].id,
          completedby: username,
          distance: selectedRowInfo.distance,
          mins: selectedRowInfo.tripendtime,
          fare: selectedRowInfo.fare || selectedValues.fare,
          feedback: selectedRowInfo.feedback,
          piclat: selectedRowInfo.toloclat,
          piclon: selectedRowInfo.toloclong,
          present_location: selectedRowInfo.toloc,
          isadditionalfare: false,
          iscredit: creditChecked || false,
       //   isgst: hillsChecked || false
        },
      };
      console.log(options);
      axiosPost(UrlConstants.tripComplete, options)
        .then((resp: any) => {
          const notificationInfo = {
            header: "Trip Completed Successfully",
            description: "",
          };
          openNotificationWithIcon("Success", notificationInfo);
          handleCancel(false);
        })
        .catch(function (error: any) {
          if (error.response) {
          }
        }
     );
   } 
  //   else {
  //     const notificationInfo = {
  //       header: "Enter Valid Time",
  //       description: "",
  //     };
  //     openNotificationWithIcon("Error", notificationInfo);
  //   }
  // };

  const calculatepackage = (
    vehiycleType: any = props.selectedRowData[0].vehicletypeid,
    distance: any,
    mins: any,
    isadditionalfare = isaddfare
  ) => {
    if (vehicleTypeList.length != 0) {
      let vehicleindex = vehicleTypeList.findIndex(
        (obj: { id: any }) => obj.id === vehiycleType
      );
      let vechicletype: any = vehicleTypeList[vehicleindex];
      let packtype = props.selectedRowData[0].packagefaretype.replace(
        "RS",
        "ours"
      );
      if (packagesList.length > 0) {
        let packindex = packagesList.findIndex(
          (obj: { package: any }) => obj.package === packtype
        );
        let packlist: any = packagesList[packindex];
        packlist.addkmcharge = vechicletype.addkmcharge;
        packlist.addminutecharge = vechicletype.addminutecharge;
        packlist.approximatefare = packlist.basefare;
        packlist.tempapproximatefare = packlist.basefare;
        let basemins = Number(packlist.baseminute.replace(/\D/g, "")) * 60;
        calculatepackageFare(
          distance,
          packlist.basekm,
          packlist.basefare,
          vechicletype.addkmcharge,
          mins,
          basemins,
          vechicletype.addminutecharge,
          isadditionalfare
        );
      }
    }
  };
  const calculatenight = (
    vehiycleType: any = props.selectedRowData[0].vehicletypeid,
    distance: any,
    mins: any,
    isadditionalfare = isaddfare
  ) => {
    if (vehicleTypeList.length != 0) {
      let nighttype = props.selectedRowData[0].nightfaretype.replace(
        "RS",
        "ours"
      );
      if (NightFareList.length > 0) {
        let packindex = NightFareList.findIndex(
          (obj: { package: any }) => obj.package === nighttype
        );
        let nightlist: any = NightFareList[packindex];
        nightlist.approximatefare = nightlist.basefare;
        nightlist.tempapproximatefare = nightlist.basefare;
        //  nightlist.AddKMCharge = NightFareList[0].AddKMCharge;
        let basemins = Number(nightlist.baseminute.replace(/\D/g, "")) * 60;
        calculatenightfare(
          distance,
          nightlist.basekm,
          nightlist.basefare,
          nightlist.AddKMCharge,
          mins,
          basemins,
          nightlist.AddMinuteCharge,
          isadditionalfare
        );
      }
    }
  };
  const calculatenightfare = (
    distance: any,
    BaseKM: any,
    BaseFare: any,
    AddKMCharge: any,
    mins: any,
    baseminute: any,
    addminutecharge: any,
    isadditionalfare?: any
  ) => {
    var approximate_fare = BaseFare;
    var KM = Number(BaseKM);
    if (KM >= Number(distance)) {
      approximate_fare = approximate_fare;
    } else if (KM < Number(distance)) {
      var extraKM = Number(distance) - KM;
      approximate_fare =
        Number(approximate_fare) + extraKM * Number(AddKMCharge);
    }
    if (mins > Number(baseminute)) {
      var extramin = Number(mins) - Number(baseminute);
      approximate_fare =
        Number(approximate_fare) + extramin * Number(addminutecharge);
    }
    let obj: any = { ...props.selectedRowData[0] };
    obj.distance = distance;
    obj.tripendtime = mins;
    if (isadditionalfare) {
      let servicefare = (approximate_fare * 30) / 100;
      approximate_fare = Number(approximate_fare) + Number(servicefare);
    }
    if (props.selectedRowData[0].isgst) {
      obj.fare =  Math.round(
        Math.round(
          (Number(approximate_fare) +
            (Number(approximate_fare) / 100) * 30 +
            Number.EPSILON) *
          100
        ) / 100);
    }
    else obj.fare = approximate_fare;
    setSelectedRowInfo(obj);
    setSelectedRowInfo(obj);
  };
  const calculatepackageFare = (
    distance: any,
    BaseKM: any,
    BaseFare: any,
    AddKMCharge: any,
    mins: any,
    baseminute: any,
    addminutecharge: any,
    isadditionalfare?: any
  ) => {
    var approximate_fare = BaseFare;
    var KM = Number(BaseKM);
    if (KM >= Number(distance)) {
      approximate_fare = approximate_fare;
    } else if (KM < Number(distance)) {
      var extraKM = Number(distance) - KM;
      approximate_fare =
        Number(approximate_fare) + extraKM * Number(AddKMCharge);
    }
    if (mins > Number(baseminute)) {
      var extramin = Number(mins) - Number(baseminute);
      approximate_fare =
        Number(approximate_fare) + extramin * Number(addminutecharge);
    }
    let obj: any = { ...props.selectedRowData[0] };
    obj.distance = distance;
    obj.tripendtime = mins;
    if (isadditionalfare) {
      let servicefare = (approximate_fare * 30) / 100;
      approximate_fare = Number(approximate_fare) + Number(servicefare);
    }
    if (props.selectedRowData[0].isgst) {
      obj.fare =
      Math.round(
        Math.round(
          (Number(approximate_fare) +
            (Number(approximate_fare) / 100) * 12 +
            Number.EPSILON) *
          100
        ) / 100);
    } else obj.fare = approximate_fare;
    setSelectedRowInfo(obj);
    setSelectedRowInfo(obj);
  };

  function diff_hours(assignedtime: any, completetime: any) {
    var diff = Math.abs(completetime - assignedtime);
    // var diff =(completetime.getTime() - assignedtime.getTime()) / 1000;
    // diff /= (60 * 60);
    var minutes = Math.floor(diff / 1000 / 60);
    return minutes;
  }

  const onchangemin = (value: any) => {
    var diffmin = diff_hours(
      new Date(props.selectedRowData[0].assignedtime),
      new Date()
    );

    if (diffmin == 0 || diffmin > 0) {
      let distance = selectedRowInfo.distance;
      if (isNaN(selectedRowInfo.distance))
        distance = Number(selectedRowInfo.distance.replace(/[^\d.-]/g, ""));
      if (selectedRowInfo.faretype.includes("Meter Fare"))
        calculateMeterFare(
          distance,
          branchid,
          selectedRowInfo.vehicletypeid,
          value
        );
      else if (selectedRowInfo.faretype.includes("Intercity Fare")) {
        calculateintercityFare(distance, selectedRowInfo.vehicletypeid, value);
      } else if (selectedRowInfo.faretype.includes("Package Fare")) {
        calculatepackage(selectedRowInfo.vehicletypeid, distance, value);
      } else if (selectedRowInfo.faretype.includes("Night Fare")) {
        calculatenight(selectedRowInfo.vehicletypeid, distance, value);
      }
    }
  };
  const hillschange = (value: any) => {
    sethillsChecked(value)
     let distance = selectedRowInfo.distance;
     if (isNaN(selectedRowInfo.distance))
       distance = Number(selectedRowInfo.distance.replace(/[^\d.-]/g, ""));
    if (selectedRowInfo.faretype.includes("Intercity Fare")) {
       calculateintercityFare(distance, selectedRowInfo.vehicletypeid, value);
      }
  }
  // const hillschange = (value: any) => {
  //   // Update the hillsChecked state
  //   sethillsChecked(value);
  
  //   // Sanitize distance if it's not a valid number
  //   let distance = selectedRowInfo.distance;
  //   if (isNaN(distance)) {
  //     distance = Number(distance.replace(/[^\d.-]/g, ""));
  //   }
  
  //   // Only calculate intercity fare when the checkbox is checked
  //   if (value && selectedRowInfo.faretype && selectedRowInfo.faretype.includes("Intercity Fare")) {
  //     calculateintercityFare(distance, selectedRowInfo.vehicletypeid, value);
  //   }
  // };

  const onFromLocationChange = (value: any, options: any) => {
    setSelectedFromLocation(options.others);
    let selectedValuesCopy = { ...selectedValues };
    selectedValuesCopy.fromloc = value;
    selectedValuesCopy.fromlocName = options.others.PlaceName;
    selectedValuesCopy.fromloclat = options.others.Latitude;
    selectedValuesCopy.fromloclong = options.others.Longitude;
    setSelectedValues(selectedValuesCopy);
    let obj = { ...selectedRowInfo };
    obj.toloclat = options.others.Latitude;
    obj.toloclong = options.others.Longitude;
    obj.toloc = options.others.PlaceName;
    setSelectedRowInfo(obj);
  };
  const onFromLocationSearch = (value: any) => {
    if (value) {
      var filtered = locationsList.filter((x: any) =>
        String(x.PlaceName.toLowerCase()).startsWith(value.toLowerCase())
      );
      setSearchFromLocationsList(filtered);
    } else {
      setSearchFromLocationsList([]);
    }
  };

  const getLocations = async (value: any) => {
    const options = {
      params: {
        cityid: value,
      },
    };
    try {
      const response = await axiosGet(UrlConstants.getLocations, options);
      setLocationsList(response);
    } catch { }
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not validate email!",
      number: "${label} is not a validate number!",
    },
  };
  const feedbackchange = (e: any) => {
    let obj = { ...selectedRowInfo };
    obj.feedback = e.target.value;
    setSelectedRowInfo(obj);
  };
  function handleEnter(event: any) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  return (
    <Modal
      destroyOnClose={true}
      className="custom-modal-form"
      title={props.title}
      width={420}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        initialValues={{
          ["km"]: selectedRowInfo.distance,
          ["time"]: selectedRowInfo.tripminutes,
        }}
        validateMessages={validateMessages}
      >
        <Form.Item name={["user", "bookingId"]} label="Booking Id" rules={[]}>
          <Input defaultValue={selectedRowInfo.id} readOnly={true} />
        </Form.Item>
        <Form.Item name={["user", "cabId"]} label="Cab Id" rules={[]}>
          <Input defaultValue={selectedRowInfo.cabid} readOnly={true} />
        </Form.Item>
        <Form.Item
          name={"km"}
          label="Km"
          rules={[{ type: "number", required: true }]}
        >
          <InputNumber onChange={onchangekm} />
        </Form.Item>
        <Form.Item
          name={"time"}
          label="Time"
          rules={[{ type: "number", required: true }]}
        >
          <InputNumber onChange={onchangemin} />
        </Form.Item>

        <Form.Item
          name={["user", "fare"]}
          label="Fare"
          rules={[{ required: true }]}
        >
          <span style={{ display: "none" }}>{selectedRowInfo.fare}</span>

          <InputNumber
            value={selectedRowInfo.fare}
            //  defaultValue={selectedRowInfo.fare}
            onChange={fareChange}
            readOnly={roleid == 1 || roleid == 2 || roleid == 7 || roleid == 10 ? false : true}
          />
        </Form.Item>

        <Form.Item label="Location">
          <span style={{ display: "none" }}>{selectedValues.fromlocName}</span>
          <Select
            showSearch
            optionFilterProp="children"
            onChange={onFromLocationChange}
            defaultValue={selectedRowInfo.toloc}
            value={selectedValues.fromlocName}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showArrow={false}
            onSearch={onFromLocationSearch}
            placeholder="Search From Location"
            notFoundContent={null}
          >
            {searchFromLocationsList.map((item: any) => (
              <Option key={item._id} value={item.ID} others={item}>
                {item.PlaceName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={["user", "feedback"]} label="Feedback">
          <Input value={selectedRowInfo.feedback} onChange={feedbackchange} />
        </Form.Item>

        <Row>
          <Col span={6} offset={1}>
            <Checkbox
              checked={creditChecked}
              onChange={() => {
                setCreditChecked(!creditChecked);
              }}
              onKeyDown={handleEnter}
            >
              {"Credit"}
            </Checkbox>
          </Col>

          <Col span={6} offset={1}>
         { (selectedRowInfo.faretype && selectedRowInfo.faretype.includes("Intercity Fare")) &&
            <Checkbox
            checked={hillsChecked}
            onChange={(e) => {
              hillschange(e.target.checked); // Pass the checked state directly
            }}
            onKeyDown={handleEnter}
          >
            </Checkbox>}
          </Col>
        </Row>

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button
            className="custom-modal-form-submit-btn"
            htmlType="submit"
            onClick={completetrip}
          >
            Complete Trip
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Completeform;
