import React from "react";
import { Table } from "antd";
import "./Apptable.scss";

const AppTable = (props: any) => {
  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      if (props.enableRadioButton) {
        props.onRadioButtonChange(selectedRows);
      } else if (props.enableCheckBox) {
        props.onCheckboxChange(selectedRows);
      }
    },
  };
  const onchange = (pagination: any, filters: any, sorter: any, extra: any) => {
    props.onchange?.(pagination);
  };

  return (
    <Table
      {...(props.customClass && { className: props.customClass })}
      {...(props.enableRadioButton && {
        rowSelection: {
          type: props.selectionType,
          ...rowSelection,
        },
      })}
      {...(props.enableCheckBox && {
        rowSelection: {
          type: props.selectionType,
          ...rowSelection,
        },
      })}
      {...(props.onRowClickEnabled && {
        onRow: (record: any, rowIndex: any) => {
          return {
            onClick: (event: any) => props.onRowClick(event, record, rowIndex), // click row
          };
        },
      })}
      bordered
      pagination={
        {
          ...props.enablePagination,
          defaultPageSize: props.defaultPageSize || 10,
        } 
      }
      scroll={props.scroll} //{{ x: 2850,y: 480 }}
      columns={props.columns}
      dataSource={props.data}
      size={props.size || ""}
      onChange={onchange}
    />
  );
};

export default AppTable;
