/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  Input,
  notification,
  Dropdown,
  Menu,
  Select,
} from "antd";
import "./DriverAttendance.scss";
import AppTable from "../../components/AppTable/AppTable";
import AppModalWithApproveDriverAttendance from "../../components/AppModal/AppModalDriverAttendanceApprove";
import AppModalWithLogoutDriverAttendance from "../../components/AppModal/AppModalWithLogoutDriverAttendance";
import AppModalDriverAttendanceLeave from "../../components/AppModal/AppModalDriverAttendanceLeave";
import AppModalDriverAttendanceBlock from "../../components/AppModal/AppModalDriverAttendanceBlock";
import AppModalDriverAttendanceAppManager from "../../components/AppModal/AppModalDriverAttendanceAppManager";
import DriverStatusUpdateModal from "../../components/AppModal/DriverStatusUpdateModal";
import axiosGet, { axiosPost } from "../../helpers/AxiosRequest";
import { UrlConstants } from "../../helpers/UrlConstantsHelper";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
//import { saveAs } from "file-saver";
//import * as XLSX from "xlsx";
//import dayjs from "dayjs";

const { Option } = Select;
const DriverAttendance = () => {
  const [approveDriverAttendanceVisible, setApproveDriverAttendanceVisible] =
    useState(false);
  const [logoutDriverAttendanceVisible, setLogoutDriverAttendanceVisible] =
    useState(false);
  const [leaveDriverAttendanceVisible, setLeaveDriverAttendanceVisible] =
    useState(false);
  const [blockDriverAttendanceVisible, setBlockDriverAttendanceVisible] =
    useState(false);
  const [
    appManagerDriverAttendanceVisible,
    setAppManagerDriverAttendanceVisible,
  ] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [searchFromLocationsList, setSearchFromLocationsList] = useState([]);
  const [copycurrentBookingGridData, setcopyCurrentBookingGridData] = useState(
    []
  );
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isblocked, setisblocked] = useState(true);
  const [nologout, setnologout] = useState(true);
  const [isleaveblocked, setleaveblocked] = useState(true);

  const [selectedRowData, setSelectedRowData]: any = useState({});
  const [TotalCount, setTotalRecordCount] = useState(0);
  const [branchid, setbranchid]: any = useState(localStorage.getItem("cityid"));
  const [isLoading, setIsLoading] = useState(false);
  const [SearchText, setsearchText]: any = useState("");
  const [Logoutcount, setlogoutcount] = useState([]);
  const [Leavecount, setleavecount] = useState([]);
  const [Blockcount, setblockcount] = useState([]);
  const [Availablecount, setavbailalecount] = useState([]);
  const [Ontrip, setontripcount] = useState([]);
  const [driverAttendanceList, setDriverAttendanceList] = useState([]);
  const [attendanceManager, setAttendanceManager] = useState("");
  const [disableblock, setdisableblock] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [roleid, setRoleid]: any = useState(
    Number(localStorage.getItem("roleid"))
  );
  const [username, setUsername]: any = useState(
    localStorage.getItem("employeename")
  );
  const [revertType, setRevertType] = useState("");
  const [cabLocation, setcabLocation] = useState([]);
  const onFromLocationChange = (value: any, options: any, cabid: any) => {
    getLocationupdate(options.others, cabid);
  };
  const onFromLocationSearch = (value: any) => {
    if (value) {
      var filtered = locationsList.filter((x: any) =>
        String(x.PlaceName.toLowerCase()).startsWith(value.toLowerCase())
      );
      setSearchFromLocationsList(filtered);
    } else {
      setSearchFromLocationsList([]);
    }
  };
  const getLocationupdate = (value: any, cabid: any) => {
    const options = {
      data: {
        cabid: cabid,
        piclat: value.Latitude,
        piclon: value.Longitude,
        present_location: value.PlaceName,
        locationupdatedby: username,
      },
    };
    axiosPost(UrlConstants.locationupdate, options).then((resp: any) => {});
  };
  const getLocations = (value: any) => {
    const options = {
      params: {
        cityid: value,
      },
    };
    axiosGet(UrlConstants.getLocations, options).then((resp: any) => {
      setLocationsList(resp);
    });
  };

  function getBackgroundColor(record: any) {
    switch (record.statuscode) {
      case 1:
        return "#f0b7b7";
      case 2:
      case 3:
      case 4:
        return "#ffdf80";
      case 5:
        return "#a5e684";
      case 10:
        return "#ffffff";
      case 11:
        return "#b7eb9c";
      case 13:
        return "#3d85c6";
      case 14:
        return "#B6917C";
      case 15:
        return "#d8abc3";
      case 16:
        return record.blockreason === "Pending payment" ? "#9e9e9e" : "#B46666";
      case 17:
      case 19:
        return "#efcede";
      case 18:
        return "#d5a6bd";
      default:
        return "";
    }
  }

  const columns = [
    {
      title: "Cab ID",
      dataIndex: "cabid",
      key: "Cab ID",
      ellipsis: true,
      fixed: "left",
      width: "100px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vechicletype",
      key: "Vehicle",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Driver Name",
      dataIndex: "drivername",
      key: "Driver Name",
      width: "150px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobileno",
      key: "Mobile",
      width: "120px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Date",
      dataIndex: "dateandtime",
      key: "Time",
      ellipsis: true,
      width: "100px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: <div>{moment(text).format("DD/MM/YYYY")}</div>,
        };
      },
    },
    {
      title: "Time",
      dataIndex: "dateandtime",
      key: "Time",
      width: "80px",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: <div>{moment(text).format(" hh:mm A")}</div>,
        };
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "Location",
      width: "250px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children:
            (roleid == 1 || roleid == 2 || roleid == 7 || roleid == 4) &&
            record.statuscode === 11 ? (
              <>
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(value, options) =>
                    onFromLocationChange(value, options, record.cabid)
                  }
                  defaultValue={record.location}
                  value={cabLocation[record.cab_id]}
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={false}
                  onSearch={onFromLocationSearch}
                  placeholder="Search Location"
                  notFoundContent={null}
                  style={{ width: "100%" }}
                >
                  {searchFromLocationsList.map((item: any) => (
                    <Option key={item._id} value={item.ID} others={item}>
                      {item.PlaceName}
                    </Option>
                  ))}
                </Select>
              </>
            ) : (
              <div>{text}</div>
            ),
        };
      },
    },

    {
      title: "Status",
      dataIndex: "driverstatus",
      key: "Status",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: "App Status",
      dataIndex: "appstatus",
      key: "App Status",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: (
            <>
              {record.statuscode !== 16 ? record.appstatus : ""}
              {record.statuscode === 16 ? record.blockreason : ""}
              {record.statuscode === 14 ? record.leavereason : ""}
              {record.statuscode === 18 ? record.hours : ""}
              {record.statuscode === 19 ? record.hours : ""}
            </>
          ),
        };
      },
    },
    {
      title: "Vehicle Id",
      dataIndex: "vehicleid",
      key: "Vehicle Id",
      width: "120px",
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: "Login",
      dataIndex: ["loginby", "openingKM"],
      key: "Loginby",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: (
            <div>
              <Row>U - {record.loginby} </Row>
              <Row>KM - {record.openingKM} </Row>
            </div>
          ),
        };
      },
    },
    {
      title: "Logout",
      dataIndex: ["logoutby", "closingKM"],
      key: "Time",
      ellipsis: true,
      render: (text: any, record: any) => {
        return {
          props: {
            style: {
              background: getBackgroundColor(record),
            },
          },
          children: (
            <div>
              <Row>U - {record.logoutby ? record.logoutby : "Auto Logout"}</Row>
              <Row>KM - {record.closingKM} </Row>
            </div>
          ),
        };
      },
    },
  ];
  const onSearchchange = (e: any) => {
    setsearchText(e.target.value);
    globalSearch(e.target.value);
  };

  const globalSearch = (searchText: string) => {
    const lowerSearchText = searchText.toLowerCase();
    const filteredData = driverAttendanceList.filter((value: any) => {
      const properties = [
        value.cabid?.toString() || "",
        value.vechicletype || "",
        value.drivername || "",
        value.mobileno?.toString() || "",
        value.location || "",
        value.vehicleid?.toString() || "",
      ].map((prop) => prop.toLowerCase());

      return properties.some((prop) => prop.includes(lowerSearchText));
    });

    setcopyCurrentBookingGridData(filteredData);
    setFilteredData(filteredData);
  };

  const showApproveDriverAttendanceFormModal = () => {
    setApproveDriverAttendanceVisible(true);
  };

  const hideApproveDriverAttendanceFormModal = (isVisible: any) => {
    setApproveDriverAttendanceVisible(isVisible);
    refresh();
  };
  const showLogoutDriverAttendanceFormModal = () => {
    setLogoutDriverAttendanceVisible(true);
  };

  const hideLogoutDriverAttendanceFormModal = (isVisible: any) => {
    setLogoutDriverAttendanceVisible(isVisible);
    refresh();
  };

  const showLeaveDriverAttendanceFormModal = () => {
    setLeaveDriverAttendanceVisible(true);
    refresh();
  };
  const hideLeavetDriverAttendanceFormModal = (isVisible: any) => {
    setLeaveDriverAttendanceVisible(isVisible);
    refresh();
  };
  const showBlockDriverAttendanceFormModal = () => {
    setBlockDriverAttendanceVisible(true);
  };
  const hideBlockDriverAttendanceFormModal = (isVisible: any) => {
    setBlockDriverAttendanceVisible(isVisible);
    refresh();
  };
  const showAppManagerDriverAttendanceFormModal = () => {
    setAppManagerDriverAttendanceVisible(true);
  };
  const hideAppManagerDriverAttendanceFormModal = (isVisible: any) => {
    setAppManagerDriverAttendanceVisible(isVisible);
  };

  const onGridRowSelect = (rowInfo: any) => {
    setIsRowSelected(rowInfo.length > 0);

    if (rowInfo.length > 0) {
      if (
        rowInfo[0].statuscode === 2 ||
        rowInfo[0].statuscode === 3 ||
        rowInfo[0].statuscode === 4 ||
        rowInfo[0].statuscode === 11 ||
        rowInfo[0].statuscode === 13 ||
        rowInfo[0].statuscode === 16 ||
        rowInfo[0].statuscode === 17
      ) {
        setisblocked(true);
      } else setisblocked(false);
      if (rowInfo[0].statuscode === 11 || rowInfo[0].statuscode === 13) {
        setnologout(false);
      } else setnologout(true);

      if (rowInfo[0].statuscode === 14) {
        setleaveblocked(false);
        setisblocked(true);
      } else if (rowInfo[0].statuscode === 10) {
        setleaveblocked(false);
      } else setleaveblocked(true);
      if (
        rowInfo[0].statuscode === 2 ||
        rowInfo[0].statuscode === 3 ||
        rowInfo[0].statuscode === 4
      )
        setdisableblock(true);
      else setdisableblock(false);
      setSelectedRowData(rowInfo[0]);
    }
  };

  useEffect(() => {
    getLocations(branchid);
    refresh();
  }, []);

  const refresh = () => {
    setIsLoading(true);
    const options = {
      params: {
        branchid: branchid,
      },
    };

    axiosGet(UrlConstants.getDriverAttendanceList, options)
      .then((resp: any) => {
        try {
          var cabloctemp: any = [];
          resp.data.forEach((e: any) => {
            e.key = e.cabid;
            let obj = { [e.cabid]: e.PlaceName };
            cabloctemp.push(obj);
          });
          setIsLoading(false);
          setcabLocation(cabloctemp);
          setTotalRecordCount(resp.tablelength);
          setDriverAttendanceList(resp.data);
          setcopyCurrentBookingGridData(resp.data);
          setlogoutcount(resp.logoutcount);
          setavbailalecount(resp.availablecount);
          setleavecount(resp.leavecount);
          setblockcount(resp.blockedcount);
          setontripcount(resp.ondutycount);
        } catch (ex) {
          console.log(ex);
        }
      })
      .catch(function (error: any) {
        setIsLoading(false);
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
      });
  };
  const showOnLeaveDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) => value.driverstatus === "On leave"
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };
  const showLoggedoutDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) => value.driverstatus === "Logout"
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };
  const showBlockedDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) => value.driverstatus === "Blocked"
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };
  const showAvailableDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) => value.statuscode === 11
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };
  const showOntripDrivers = () => {
    let filteredData = driverAttendanceList.filter(
      (value: any) =>
        value.statuscode === 2 ||
        value.statuscode === 3 ||
        value.statuscode === 4
    );
    setcopyCurrentBookingGridData(
      filteredData.sort((a: any, b: any) => a.cabid.localeCompare(b.cabid))
    );
  };

  const resumeduty = () => {
    const options: any = {
      data: {
        cabid: selectedRowData.cabid,
      },
    };

    axiosPost(UrlConstants.updateDriverStatus, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Driver Resumed Duty",
          description: "Cabid " + selectedRowData.cabid + " status updated",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Driver Resume Failed",
          description: "Error Occurred while resuming Driver status",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const showAllDrivers = () => {
    setcopyCurrentBookingGridData(driverAttendanceList);
  };
  const onAttendanceManagerChange = (e: any) => {
    console.log(e);
    if (e.key == 1) {
      showConfirmModal();
      setRevertType("login");
    } else if (e.key == 2) {
      showConfirmModal();
      setRevertType("logout");
    } else if (e.key == 4) {
      /* else if(value==3)
      revertleave(); */
      showConfirmModal();
      setRevertType("ontrip");
    }
    setAttendanceManager(e.key);
  };

  const revertleave = () => {
    const options: any = {
      data: {
        cabid: selectedRowData.cabid,
      },
    };

    axiosPost(UrlConstants.revertlogout, options)
      .then((resp: any) => {
        const notificationInfo: any = {
          header: "Logout Reverted  Successfully",
          description: "Cabid " + selectedRowData.cabid + " leave reverted",
        };
        openNotificationWithIcon("Success", notificationInfo);
      })
      .catch(function (error: any) {
        if (error.response) {
          console.log(error.response.data, error.response.status);
        }
        const notificationInfo = {
          header: "Logout Revert Failed",
          description: "Error Occurred while Reverting Logout",
        };
        openNotificationWithIcon("Error", notificationInfo);
      });
  };
  const openNotificationWithIcon = (type: any, info: any) => {
    if (type === "Success") {
      notification.success({
        message: info.header,
        description: info.description,
      });
    } else if (type === "Error") {
      notification.error({
        message: info.header,
        description: info.description,
      });
    }
  };

  const showConfirmModal = () => {
    setConfirmModalVisible(true);
  };
  const hideConfirmModal = (isVisible: any) => {
    setConfirmModalVisible(isVisible);
    refresh();
  };

  const dataToExport = driverAttendanceList.map((value: any) => ({
    ...value,
    mobileno: value.mobileno.toString().concat(""),
    Date: moment(value.dateandtime).format("DD/MM/YYYY"),
    Time: moment(value.dateandtime).format("hh:mm A"),
  }));

  const items = [
    {
      label: "Revert Login",
      key: "1",
      disabled:
        !(roleid === 1 || roleid === 2 || roleid === 7) ||
        !(
          selectedRowData.statuscode === 11 || selectedRowData.statuscode === 1
        ),
    },
    {
      label: "Revert Logout",
      key: "2",
      disabled:
        !(roleid === 1 || roleid === 2 || roleid === 7) ||
        !(
          selectedRowData.statuscode === 10 || selectedRowData.statuscode === 13
        ),
    },
    {
      label: "Available",
      key: "4",
      disabled:
        !(roleid === 1 || roleid === 2 || roleid === 4 || roleid === 7) ||
        !(
          selectedRowData.statuscode === 2 ||
          selectedRowData.statuscode === 3 ||
          selectedRowData.statuscode === 4
        ),
    },
  ];
  // Handle click events

  const menu = (
    <Menu onClick={onAttendanceManagerChange}>
      {items.map((item) => (
        <Menu.Item key={item.key} disabled={item.disabled}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  // const exportToExcel = (filename: any, columns: any, data: any) => {
  //   const ws = XLSX.utils.json_to_sheet(
  //     data.map((row: any) => {
  //       return columns.reduce((acc: any, col: any) => {
  //         if (col.title === "Login" || col.title === "Logout") {
  //           acc[col.title] = row[col.dataIndex[0]];
  //           let km_title = col.title === "Login" ? "Start Km" : "End Km";
  //           acc[km_title] = row[col.dataIndex[1]];
  //         } else acc[col.title] = row[col.dataIndex];
  //         // console.log(col);
  //         return acc;
  //       }, {});
  //     }),
  //     {
  //       header: columns.map((col: any) => col.title),
  //     }
  //   );
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  //   const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //   saveAs(new Blob([wbout], { type: "application/octet-stream" }), filename);
  // };

  // const handleExport = (format: any) => {
  //   if (format === "excel") {
  //     let files = dayjs().format("DD-MM-YYYY");
  //     exportToExcel(
  //       "Driver Attandance-" + files + ".xlsx",
  //       columns,
  //       copycurrentBookingGridData
  //     );
  //   }
  // };
  return (
    <div>
      <div className="sticky">
        {roleid !== 8 && (
          <Row gutter={16} style={{ display: "flex" }}>
            <Col span={2}>
              <Button
                className={!isblocked ? "driver-attendance-btn-approve" : ""}
                disabled={
                  isblocked ||
                  (roleid === 5 &&
                    moment().isAfter(moment("10:00:00", "HH:mm:ss")))
                }
                onClick={showApproveDriverAttendanceFormModal}
              >
                Approve
              </Button>
            </Col>
            <Col span={2}>
              <Button
                className={!nologout ? "driver-attendance-btn-logout" : ""}
                disabled={nologout}
                onClick={showLogoutDriverAttendanceFormModal}
              >
                Logout
              </Button>
            </Col>
            <Col span={2}>
              <Button
                className={!isleaveblocked ? "driver-attendance-btn-leave" : ""}
                disabled={isleaveblocked}
                onClick={showLeaveDriverAttendanceFormModal}
              >
                Leave
              </Button>
            </Col>
            <Col span={2}>
            {(roleid == 1 || roleid == 2 || roleid == 8 ) && (
              <Button
                className={!disableblock ? "driver-attendance-btn-block" : ""}
                onClick={showBlockDriverAttendanceFormModal}
                disabled={disableblock}
              >
                Block
              </Button>)}
            </Col>

            <Col span={5}>
              {(roleid === 1 ||
                roleid === 2 ||
                roleid === 4 ||
                roleid === 7) && (
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Button className="driver-attendance-btn-attmanager">
                    Attendance Manager <DownOutlined />
                  </Button>
                </Dropdown>
              )}
            </Col>
            {(selectedRowData?.statuscode === 18 ||
              selectedRowData?.statuscode === 19 ||
              selectedRowData?.statuscode === 17) && (
              <Col span={2}>
                <Button
                  className="driver-attendance-btn-block"
                  onClick={resumeduty}
                >
                  Resume
                </Button>
              </Col>
            )}

            <Col span={4} className="driver-attendance-total-count">
              <span style={{ fontWeight: "bold" }}>
                Total Attendance: {TotalCount}
              </span>
            </Col>
            {(roleid === 1 || roleid === 2) && (
              <Col span={2} offset={1}>
                {/* <CSVLink
                filename={"Enquiry.csv"}
                headers={headersToExport}
                data={dataToExport}
                className="btn btn-primary"
              > */}
                {/* <Button
                  onClick={() => handleExport("excel")}
                  className="booking-btn-blockExport"
                  // onClick={ExportToExcel}
                >
                  Export
                </Button> */}
                {/* </CSVLink> */}
              </Col>
            )}
          </Row>
        )}
        <Row gutter={16} style={{ display: "flex", padding: "7px 0" }}>
          <Col span={3}>
            <Button
              className="driver-attendance-btn-loggedout"
              onClick={showLoggedoutDrivers}
            >
              Logout - {Logoutcount}
            </Button>
          </Col>
          <Col span={3}>
            <Button
              className="driver-attendance-btn-onleave"
              onClick={showOnLeaveDrivers}
            >
              On Leave - {Leavecount}
            </Button>
          </Col>
          <Col span={3}>
            <Button
              className="driver-attendance-btn-blocked"
              onClick={showBlockedDrivers}
            >
              Inactive - {Blockcount}
            </Button>
          </Col>
          <Col span={3}>
            <Button
              className="driver-attendance-btn-onduty"
              onClick={showAvailableDrivers}
            >
              Available - {Availablecount}
            </Button>
          </Col>
          <Col span={3}>
            <Button
              className="driver-attendance-btn-ontrip"
              onClick={showOntripDrivers}
            >
              On Trip - {Ontrip}
            </Button>
          </Col>
          <Col span={3}>
            <Button
              className="driver-attendance-btn-all"
              onClick={showAllDrivers}
            >
              All - {TotalCount}
            </Button>
          </Col>
          {roleid === 8 && (
            <Col span={3}>
              <Button
                className="driver-attendance-btn-block"
                onClick={showBlockDriverAttendanceFormModal}
                disabled={disableblock}
              >
                Block
              </Button>
            </Col>
          )}

          <Col span={3}>
            <Input
              placeholder="Search Text"
              className="search"
              onChange={onSearchchange}
            />
          </Col>
        </Row>
      </div>
      <Row className="driver-attendance-table-row" style={{ display: "unset" }}>
        <Spin tip="Loading..." spinning={isLoading}>
          <AppTable
            columns={columns}
            data={copycurrentBookingGridData}
            enableRadioButton={true}
            rowClassName={(record: any) => getBackgroundColor(record)}
            onRadioButtonChange={onGridRowSelect}
            enablePagination={true}
            defaultPageSize={20}
            selectionType="radio"
          ></AppTable>
        </Spin>
      </Row>
      <AppModalWithApproveDriverAttendance
        title="Approve"
        visible={approveDriverAttendanceVisible}
        hideModal={hideApproveDriverAttendanceFormModal}
        selectedRowData={selectedRowData}
      />
      <AppModalWithLogoutDriverAttendance
        title="Logout"
        visible={logoutDriverAttendanceVisible}
        hideModal={hideLogoutDriverAttendanceFormModal}
        selectedRowData={selectedRowData}
      />
      <AppModalDriverAttendanceLeave
        title="Leave"
        visible={leaveDriverAttendanceVisible}
        hideModal={hideLeavetDriverAttendanceFormModal}
        selectedRowData={selectedRowData}
      />
      <AppModalDriverAttendanceBlock
        title="Block"
        visible={blockDriverAttendanceVisible}
        hideModal={hideBlockDriverAttendanceFormModal}
        width={700}
        selectedRowData={selectedRowData}
        // modalGridColumns={modalReAssignColumns}
        // modalGridData={[]}
      />
      <AppModalDriverAttendanceAppManager
        title="App Manager"
        visible={appManagerDriverAttendanceVisible}
        hideModal={hideAppManagerDriverAttendanceFormModal}
        width={700}
        selectedRowData={selectedRowData}
      />
      <DriverStatusUpdateModal
        visible={confirmModalVisible}
        hideModal={hideConfirmModal}
        selectedRowData={selectedRowData}
        revertType={revertType}
      />
    </div>
  );
};

export default DriverAttendance;